    <!-- 用户指南侧边栏 -->
<template>
  <div class="sidecontainer">
    <div class="sideManu-name">
      用户指南
    </div>
    <ul class="sideManue">
      <li
        v-for="(item,index) in tabList"
        :key="item.key"
        :class="{'active':tabIdx==item.key}"
      >
        <i class="active_line"></i>
         <span class="icon iconfont" v-html="item.iconleft"></span>
        <div @click="goto(item,index)">{{item.label}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'side',

  data () {
    return {
      isCollapse:true,
      tabIdx: 'CopyRight',
      tabList: [
        {
          label: '版权相关',
          router: '/HelpCenter/CopyRight',
          key: 'CopyRight'
        },
        {
          label: '帮助中心',
          router: '/HelpCenter/Help',
          key: 'Help'
        },
        
      ]
    }
  },
  watch:{
    $route(to,from){
      console.log(to.name)
     this.tabIdx=to.name
    }
},
  mounted () {
    this.tabIdx = this.$route.name
  },
  methods: {
    goto (item, index) {
      this.$router.push({
        path: item.router,
        query: {}
      });
      this.tabIdx = item.key
    }
  }
}
</script>

<style lang='scss' scoped>
.sidecontainer{
  width: 192px;
  padding-bottom: 16px;
  height: 100%;
  background: #F2F8FF;
}
.sideManu-name{
  font-size: 18px;
  font-weight: 500;
  line-height: 88px;
  padding-left: 24px;
}
.sideManue {
  li{
    padding:14px 0 14px 32px;
    line-height: 20px;
    position: relative;
    .icon{
    font-size: 22px;
    position: absolute;
    left: 24px;
    top: 14px;
    opacity: 0.8;
    }
    cursor: pointer;
  }
  li.active,li:hover {
    color: #007DFF;
    background: #ffffff;
    .active_line{
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 2px;
      height: 40px;
      background: #007DFF;
    }
    .icon{
      opacity: 1;
    }
  }
}
@media (max-width: 992px){
   .sideManue{
     li{
       padding-left: 0;
     }
   }
}
@media (max-width: 576px){
   .sidecontainer{
     width:100%;
     height: auto;
     padding-bottom: 0;
     .sideManu-name{
       background: #fff;
       line-height: 1.5em;
       padding:24px 16px 16px;
     }
   }
   .sideManue{
     display: flex;
     li{
       flex: 1;
       text-align: center;
     }
     li.active{
       .active_line{
         width:100%;
         height: 2px;
         top:0;
         left: 0;
       }
     }
   }
}
</style>
